#screen {
  min-width: calc(100% - 16px);
  height: calc(100% - 16px);
  padding: 8px;
  display: flex;
  flex-direction: column-reverse;
}

#screen .bottom {
}

#screen .color-constant {
  color: #ae81ff; /* Constant */
}
#screen .color-error {
  color: #e6db74; /* Error */
}
#screen .color-error-msg {
  color: #f92672; /* ErrorMsg */
}
#screen .color-cursor {
  color: #000000; /* Cursor */
}
#screen .color-debug {
  color: #bca3a3; /* Debug */
}
#screen .color-define {
  color: #66d9ef; /* Define */
}
#screen .color-delimiter {
  color: #8f8f8f; /* Delimiter */
}
#screen .color-diff-change {
  color: #89807d; /* DiffChange */
}
#screen .color-diff-delete {
  color: #960050; /* DiffDelete */
}
#screen .color-directory {
  color: #a6e22e; /* Directory */
}
#screen .color-folded {
  color: #465457; /* Folded */
}
#screen .color-identifier {
  color: #fd971f; /* Identifier */
}
#screen .color-ignore {
  color: #808080; /* Ignore */
}
#screen .color-macro {
  color: #c4be89; /* Macro */
}
.color-background {
  background-color: #1e1d12; /* Background */
}
