html,
body,
#root {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1e1d12; /* Background */
}

* {
  font-family: Menlo, Courier New, monospace, serif;
  font-size: 14px;
  color: white;
}

@media (max-width: 1024px) {
  * {
    font-size: 10px;
  }
}
