#welcome .ascii-art {
  font-size: 10px;
}

@media (max-width: 1024px) {
  #welcome .ascii-art {
    font-size: 6px;
  }
}

#welcome .message {
  margin-bottom: 20px;
}
