#about .avatar {
  margin-top: 5px;
  height: 150px;
}

#about li {
  list-style: none;
}

#about ul {
  margin: 0;
  padding: 0;
}
