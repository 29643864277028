.App {
  width: 100%;
  height: 100%;
}

.command-link {
  text-decoration: underline;
}

a {
  text-decoration: underline;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
}

/* selected link */
a:active {
  color: white;
}
